$(document).ready(function () {
    var $header = $('header');
    var lastScrollTop = 0;
    $(window).scroll(function () {
        if ($(window).scrollTop() > 110) {
            $header.addClass('background--white');
        } else {
            $header.removeClass('background--white');
        }
        var st = $(window).scrollTop();
        if (st < lastScrollTop) {

            $header.removeClass('header--fixed--out');

        } else if(st > 0 && lastScrollTop > 0) {
            $header.addClass('header--fixed--out');

        }
        lastScrollTop = st;

    });
    $('.menu__link[href="/#referenzen"]').click(function () {
        $("html, body").stop().animate({scrollTop: $('#referenzen').offset().top}, 600);
        return false;
    });
    if (window.location.hash == '#referenzen') {

        // $("html, body").stop().animate({scrollTop: $('#referenzen').offset().top - 90}, 600);
    }

    if (window.location.hash.length) {
        $(window).ready(function () {
            var scrollTo = window.location.hash;
            $("html, body").stop().animate({scrollTop: $(scrollTo).offset().top}, 600);
        });

    }
    $('#js__mobiletoggle__checkbox').click(function () {
        console.log(1);
        if($(this).is(':checked')) {
            $header.addClass('background--white');
        }
        else {
            $header.removeClass('background--white');
        }
    });


});